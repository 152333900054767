import React from 'react';

const TwitterIcon = () => (
  <svg viewBox="0 0 17 15" width="25" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M15.1453 4.32361V4.76161C15.1453 6.37661 14.7963 7.93561 14.0983 9.43961C13.4003 10.9426 12.3223 12.2156 10.8643 13.2586C9.40529 14.3026 7.71829 14.8236 5.80029 14.8236C3.94629 14.8236 2.27029 14.2966 0.769287 13.2416C0.936287 13.2646 1.19629 13.2756 1.55029 13.2756C3.11329 13.2756 4.47729 12.7706 5.64429 11.7616C4.93629 11.7616 4.30029 11.5316 3.73829 11.0716C3.17625 10.6126 2.76285 9.9974 2.55029 9.30361C2.69629 9.34861 2.90529 9.37061 3.17529 9.37061C3.48829 9.37061 3.77929 9.33661 4.05029 9.27061C3.30029 9.09061 2.67529 8.67561 2.17529 8.02461C1.67529 7.37461 1.42529 6.63361 1.42529 5.80361V5.73661C1.90529 6.02761 2.39429 6.17361 2.89429 6.17361C1.91429 5.50061 1.42529 4.52461 1.42529 3.24561C1.42529 2.66261 1.58229 2.06761 1.89529 1.46161C3.62329 3.79561 5.87329 5.02961 8.64529 5.16361C8.58294 4.89911 8.55107 4.62835 8.55029 4.35661C8.55029 3.39161 8.86829 2.56161 9.50329 1.86661C10.1403 1.17061 10.9163 0.823608 11.8333 0.823608C12.7913 0.823608 13.5933 1.19361 14.2393 1.93361C15.0513 1.75461 15.7493 1.47361 16.3323 1.09361C16.0823 1.92361 15.6033 2.57361 14.8953 3.04461C15.6253 2.93261 16.2493 2.74161 16.7703 2.47261C16.3417 3.18034 15.7916 3.80694 15.1453 4.32361Z" />
    </g>
  </svg>
);

export default TwitterIcon;
